<template>
  <MoleculeCard
    :title="$t('pages.profile.edit.title')"
    :loading="loading"
    :outline="false"
    class="card-form"
  >
    <template slot="actions">
      <AtomButton
        @click="saveHandler"
        color="primary"
        :loading="submitLoading"
        :disabled="!dirty || $v.$anyError"
      >
        {{ $t('action.save') }}
      </AtomButton>
    </template>
    <template #content>
      <MoleculeForm
        :validator="$v"
        :loading="submitLoading"
        :disabled="submitLoading"
        @submit="saveHandler"
      >
        <div class="card-body">
          <AtomInput
            v-model.trim="form.surname"
            label="surname"
            :class="[$options.classes.input, 'mb-3']"
            :input-wrapper-class="$options.classes.wrapper"
            :label-class="$options.classes.label"
          />
          <AtomInput
            v-model.trim="form.name"
            label="name"
            :class="[$options.classes.input, 'mb-3']"
            :input-wrapper-class="$options.classes.wrapper"
            :label-class="$options.classes.label"
          />
          <AtomInput
            v-model.trim="form.patronymic"
            label="patronymic"
            :class="[$options.classes.input, 'mb-3']"
            :input-wrapper-class="$options.classes.wrapper"
            :label-class="$options.classes.label"
          />
          <AtomInput
            v-model.trim="form.phone"
            label="phone"
            type="phone"
            :class="[$options.classes.input, 'mb-3']"
            :input-wrapper-class="$options.classes.wrapper"
            :label-class="$options.classes.label"
          />
          <AtomInput
            v-model.trim="form.position"
            label="position"
            :class="[$options.classes.input, 'mb-3']"
            :input-wrapper-class="$options.classes.wrapper"
            :label-class="$options.classes.label"
          />
        </div>
        <AtomDivider class="m-0">{{ $t('settings.title') }}</AtomDivider>
        <div class="card-body">
          <AtomChoose
            v-model="form.defaultPerPage"
            type="selectbox"
            label="settings.perPage"
            :class="[$options.classes.input, 'mb-3']"
            input-wrapper-class="col-auto"
            :label-class="$options.classes.label"
            :options="$options.perPages"
            :inline="true"
            :multiple="false"
          />
          <AtomChoose
            type="selectbox"
            :label="$t('theme.label')"
            :description="$t('theme.description')"
            :class="$options.classes.input"
            input-wrapper-class="col-auto"
            :label-class="$options.classes.label"
            :options="getThemes()"
            :inline="true"
            :multiple="false"
            @input="changeTheme($event)"
            :value="choosedTheme"
          />
        </div>
        <AtomDivider class="m-0">{{ $t('notification.many.title') }}</AtomDivider>
        <div class="card-body">
          <AtomChoose
            v-model="form.notifySms"
            type="switch"
            label="settings.notifySms"
            :class="$options.classes.input"
            input-wrapper-class="col-auto"
            :label-class="$options.classes.label + ' d-flex w-100'"
            :inline="true"
            :multiple="false"
          />
        </div>
      </MoleculeForm>
    </template>
  </MoleculeCard>
</template>

<script>
import { pick, isEqual } from 'lodash'
import { mapGetters, mapActions } from 'vuex'
import { required, minLength } from 'vuelidate/lib/validators'
import { getThemes, perPages } from '@/helpers'

import MoleculeCard from '@/components/MoleculeCard'
import MoleculeForm from '@/components/MoleculeForm'
import AtomInput from '@/components/AtomInput'
import AtomChoose from '@/components/AtomChoose'
import AtomButton from '@/components/AtomButton'
import AtomDivider from '@/components/AtomDivider'

export default {
  title: vm => vm.$t('pages.profile.edit.title'),

  components: {
    MoleculeCard,
    MoleculeForm,
    AtomInput,
    AtomChoose,
    AtomButton,
    AtomDivider,
  },

  data: () => ({
    form: {
      surname: '',
      name: '',
      patronymic: '',
      phone: '',
      position: '',
      defaultPerPage: '',
      notifySms: false,
    },

    dirty: false,
    submitLoading: false,
    loading: true,
  }),

  validations: {
    form: {
      surname: { required, minLength: minLength(3) },
      name: { required, minLength: minLength(3) },
      phone: { required, minLength: minLength(18) },
      position: { minLength: minLength(3) },
    },
  },

  computed: mapGetters(['info', 'companies', 'choosedTheme']),

  watch: {
    form: {
      handler () {
        this.dirty = !isEqual(this.$options.originalForm, this.form)
      },
      deep: true,
    },
  },

  async mounted () {
    try {
      await this.fetchCompanies()

      this.form = {
        ...pick(this.info, ['surname', 'name', 'patronymic', 'phone', 'position', 'notifySms']),
        defaultPerPage: this.info.defaultPerPage || 5,
      }
      this.$options.originalForm = { ...this.form }
    } catch {} finally {
      this.loading = false
    }
  },

  methods: {
    ...mapActions(['updateInfo', 'fetchCompanies', 'changeTheme']),

    getThemes,

    async saveHandler () {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }

      try {
        this.submitLoading = true
        await this.updateInfo(this.form)
        this.$options.originalForm = { ...this.form }
        this.dirty = false
        this.$notify({
          title: this.$t('action.success'),
          text: this.$t('profile.success.message'),
          type: 'success',
        })
      } catch {} finally {
        this.submitLoading = false
      }
    },
  },

  perPages,

  classes: {
    wrapper: 'col-12 col-lg-6',
    label: 'col-12 col-lg-3 col-form-label',
    input: ['form-group', 'row', 'justify-content-between'],
  },
}
</script>
