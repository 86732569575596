<template>
  <div>
    <template v-if="!isBoolean">
      <AtomLabel
        v-if="label"
        :label="preparedLabel"
        :class="labelClasses"
      >
        <template #description v-if="description || !!$slots.description">
          <small class="form-hint">
            <slot name="description">{{ description }}</slot>
          </small>
        </template>
      </AtomLabel>
      <div :class="inputWrapperClass">
        <template v-if="!isSelectbox">
          <label
            v-for="option, index in preparedOptions"
            :key="index"
            class="form-check"
            :class="itemClasses"
          >
            <input
              class="form-check-input"
              :class="inputClasses"
              :type="preparedType"
              :disabled="isDisabled"
              :value="option.value"
              :checked="option.checked"
              @click.passive="updateInput(option.value)"
            >
            <span class="form-check-label">{{ option.label }}</span>
            <span
              v-if="option.label"
              class="form-check-description"
            >{{ option.description }}</span>
          </label>
        </template>
        <div
          v-else
          class="form-selectgroup"
          :class="groupClasses"
        >
          <div
            v-for="option, index in preparedOptions"
            :key="index"
          >
            <slot
              :option="option"
              :disabled="isDisabled"
              :type="preparedType"
              :handler="updateInput"
            >
              <label
                :key="index"
                class="form-selectgroup-item"
                :class="itemClasses"
              >
                <input
                  class="form-selectgroup-input"
                  :type="preparedType"
                  :disabled="isDisabled"
                  :value="option.value"
                  :checked="option.checked"
                  @click.passive="updateInput(option.value)"
                >
                <span class="form-selectgroup-label">
                  <component
                    :is="option.icon + '-icon'"
                    v-if="option.icon"
                    :class="{ 'me-1': option.label }"
                  />{{ option.label }}
                </span>
              </label>
            </slot>
          </div>
        </div>
        <span
          v-if="error"
          class="invalid-feedback"
          v-text="error"
        />
      </div>
    </template>
    <template v-else>
      <AtomLabel
        v-if="direction === 'ltr'"
        class="form-check"
        :class="labelClasses"
        :label="preparedLabel"
      >
        <template #default="{ label }">
          <input
            class="form-check-input"
            :class="inputClasses"
            :type="preparedType"
            :disabled="isDisabled"
            :value="checked"
            :checked="checked"
            @click.passive="updateInput($event.target.checked)"
          >
          <span
            v-if="label || !!$slots.default"
            class="form-check-label fw-normal"
            :class="labelClasses"
          >
            <slot>{{ preparedLabel }}</slot>
          </span>
        </template>
      </AtomLabel>
      <label
        v-else
        class="row"
        :class="labelClasses"
      >
        <span
          class="col"
          :class="{ 'text-danger': isInvalid }"
        >
          <slot>{{ preparedLabel }}</slot>
        </span>
        <span class="col-auto">
          <label
            class="form-check form-check-single"
            :class="itemClasses"
          >
            <input
              class="form-check-input"
              :class="inputClasses"
              :type="preparedType"
              :disabled="isDisabled"
              :value="checked"
              :checked="checked"
              @click.passive="updateInput($event.target.checked)"
            >
          </label>
        </span>
        <div
          v-if="error"
          class="col-12"
        >
          <span class="invalid-feedback">{{ error }}</span>
        </div>
      </label>
    </template>
  </div>
</template>

<script>
import AtomLabel from '@/components/AtomLabel'

import FormMixin from '@/mixins/FormMixin'
import InputMixin from '@/mixins/InputMixin'

export default {
  name: 'AtomChoose',

  components: {
    AtomLabel,
  },

  mixins: [FormMixin, InputMixin],

  props: {
    options: {
      type: Array,
      default: () => [],
    },

    value: [String, Number, Array, Object, Boolean],

    type: {
      type: String,
      default: 'checkbox',
      validator: value => ['checkbox', 'radio', 'switch', 'selectbox'].includes(value),
    },

    inline: {
      type: Boolean,
      default: false,
    },

    valueLabel: {
      type: String,
      default: 'label',
    },

    reduce: {
      type: Function,
      default: option => Object.hasOwnProperty.call(option, 'value') ? option.value : option,
    },

    multiple: {
      type: Boolean,
      default: true,
    },

    variant: {
      type: String,
      validator: value => ['rounded'].includes(value),
    },

    trueValue: {
      default: true,
    },

    falseValue: {
      default: false,
    },

    direction: {
      type: String,
      default: 'rtl',
      validator: value => ['rtl', 'ltr'].includes(value),
    },
  },

  data () {
    return {
      checked: this.options.length && this.multiple && !Array.isArray(this.value) ? [this.value] : this.value,
    }
  },

  computed: {
    isSwitch () {
      return this.type === 'switch'
    },

    isSelectbox () {
      return this.type === 'selectbox'
    },

    isBoolean () {
      return !this.options.length
    },

    itemClasses () {
      const classes = {}

      if (this.isSwitch) {
        classes['form-switch'] = true
      }

      if (this.inline) {
        classes['form-check-inline'] = true
      }

      if (this.variant === 'rounded') {
        classes['form-selectgroup-pills'] = true
      }

      return classes
    },

    groupClasses () {
      return !this.inline && this.isSelectbox ? 'd-flex flex-column' : null
    },

    preparedType () {
      return this.isSwitch ? 'checkbox' : this.isSelectbox ? (this.multiple ? 'checkbox' : 'radio') : this.type
    },

    preparedOptions () {
      return this.options.map(option => {
        const value = this.reduce(option)
        return {
          ...option,
          value,
          label: option[this.valueLabel] || (typeof option === 'string' || Number.isInteger(option) ? option : ''),
          checked: this.multiple && Array.isArray(this.checked) ? this.checked.includes(value) : this.checked === value,
        }
      })
    },
  },

  watch: {
    value (value) {
      this.checked = !this.isBoolean && this.multiple && !Array.isArray(value) ? [value] : value
    },
  },

  methods: {
    updateInput (value) {
      if (!this.isBoolean) {
        const oldValue = this.multiple && !Array.isArray(this.checked) ? [this.checked] : this.checked
        if (this.multiple) {
          if (oldValue.includes(value)) {
            const index = oldValue.indexOf(value)
            if (index !== -1) {
              this.checked.splice(index, 1)
            }
          } else {
            this.checked = [...oldValue, value]
          }
        } else {
          if (value === this.checked) {
            this.checked = ''
          } else {
            this.checked = value
          }
        }
      } else {
        this.checked = !this.checked ? this.trueValue : this.falseValue
      }

      this.$emit('input', this.checked)
    },
  },
}
</script>

<style lang="scss" scoped>
  @import "~bootstrap/scss/functions";
  @import '~@tabler/core/src/scss/variables';

  .form-check-inline {
    margin: {
      top: .25rem;
      bottom: .25rem;
    };
  }

  .form-selectgroup-label {
    .theme-dark & {
      &:hover {
        background-color: $dark-mode-lighten;
        color: $white;
      }
    }
  }

  .form-label.form-check.required::after {
    content: '';
    display: none;
  }
</style>
